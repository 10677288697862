/* istanbul ignore file */
import React, { useEffect, useState, useRef } from 'react'
import {
  Button,
  Card,
  Row,
  Col,
  Skeleton,
  Form,
  Input,
  Tooltip
} from 'antd'
import { ReactGrid } from '@silevis/reactgrid'
import { useParams } from 'react-router-dom'
import fileDownload from 'js-file-download'
import { Helmet } from 'react-helmet'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import _ from 'lodash'

import '../component/reactgrid/styles.scss'
import {
  DownloadOutlined,
  SaveOutlined,
  EditOutlined,
  CloseOutlined,
  CloudSyncOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import Notification from '../services/Notification'
import {
  postPurchaseOrderDetail,
  postSerialNumber,
  putUpdatePurchaseOrderDetail,
  postExport,
  postVerifyPurchaseOrder
} from '../services/TdsSaasUnitDelivery'
import Layout from '../component/layout/Layout'
import SkeletonLoading from '../component/SkeletonLoading'
import { GridDropdownCellTemplate } from '../component/rateCell/GridDropdownCellTemplate'
import { GridDateTimeCellTemplate } from '../component/rateCell/GridDateTimeCellTemplate'
import { applyChangesToData } from '../utils/common'

dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.tz.setDefault('America/New_York')

const HEADING_ROW_HEIGHT = 40
const headerRow = {
  height: HEADING_ROW_HEIGHT,
  rowId: 'header',
  cells: [
    { type: 'header', text: 'No.' },
    { type: 'header', text: 'Part' },
    { type: 'header', text: 'Serial Number' },
    { type: 'header', text: 'Installation Date' },
    { type: 'header', text: 'Delivery Date' },
    { type: 'header', text: 'Package Tracking Number' },
    { type: 'header', text: 'Logistic Company' },
    { type: 'header', text: 'MDM Register' },
    { type: 'header', text: 'Ethernet Mac Address' },
    { type: 'header', text: 'Wifi Mac Address' },
  ]
}

const getColumns = () => [
  { columnId: 'no', width: 70, isDisabled: true },
  { columnId: 'part', width: 400 },
  { columnId: 'serialNumber', width: 180 },
  { columnId: 'installationDate', width: 240 },
  { columnId: 'deliveryDate', width: 240 },
  { columnId: 'packageTrackingNumber', width: 200 },
  { columnId: 'logisticCompany', width: 150 },
  { columnId: 'mdmRegister', width: 150 },
  { columnId: 'ethernetMacAddress', width: 180 },
  { columnId: 'wiFiMacAddress', width: 180 },
]

const App = (props) => {
  const [form] = Form.useForm()
  const initialized = useRef(false)
  let { uuid } = useParams()
  const [editMode, setEditMode] = useState(false)
  const [hideEdit, setHideEdit] = useState(false)
  const [loadingFirst, setLoadingFirst] = useState(true)
  const [dataItem, setDataItem] = useState([])
  const cloneItem = useRef([])
  const [logingApi, setLogingApi] = useState(false)
  const [logingMacApi, setMacLogingApi] = useState(false)
  const [verifySuccess, setVerifySuccess] = useState(false)
  const [purchaseOrderId, setPurchaseOrderId] = useState('')
  const [loadingApi, setLoadingApi] = useState(false)
  const [onNotiError, setOnNotiError] = useState(false)
  const userToken = useRef(false)
  const Noti = new Notification()

  uuid = props?.uuid ?? uuid

  useEffect(() => {
    
    if (!initialized.current) {
      initialized.current = true
      setLoadingFirst(false)
    }
  }, [])

  const fnPostPurchaseOrderDetail = () => {
    setLogingApi(true)
    postPurchaseOrderDetail(
      { Authorization: `Bearer ${userToken.current}` },
      {},
      uuid
    ).then(res => {
      setLoadingFirst(false)
      const { resData } = res.data
      
      let rawData = resData?.customer_delivery ?? []
      let newData = []
      let allData = 0
      rawData.forEach(r => {
        r?.unit_delivery?.forEach(rr => {
          newData.push(rr)
        })
      })
      newData = newData.map(r => {
        
        if (r?.serialNumber) allData++
        return {
          part: r?.part ?? '',
          serialNumber: r?.serialNumber ?? '',
          replacementBy: r?.replacementBy ?? '',
          installationDate: r?.installationDate ? dayjs(r.installationDate).$d : null,
          deliveryDate: r?.deliveryDate ? dayjs(r.deliveryDate).$d : null,
          packageTrackingNumber: r?.packageTrackingNumber ?? '',
          mdmRegister: r?.mdmRegister ?? '',
          logisticCompany: r?.logisticCompany ?? '',
          ethernetMacAddress: r?.ethernetMacAddress ?? '',
          wiFiMacAddress: r?.wiFiMacAddress ?? ''
        }
      })
      newData = _.orderBy(newData, 'serialNumber')

      if (allData === newData.length) setHideEdit(true)
      setDataItem(newData)
      cloneItem.current = JSON.parse(JSON.stringify(newData))
      setLoadingFirst(false)
      setLogingApi(false)
    }).catch(error => {
      console.error(error)
      setPurchaseOrderId('')
      setVerifySuccess(false)
      setLoadingFirst(false)
      setLogingApi(false)
      if(!onNotiError)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription,
          onCancel: () => {
            setOnNotiError(false)
          }
        })
      setOnNotiError(true)
    })
  }

  const fnPostVerifyPurchaseOrder = (id) => {
    let body = {
      uuid: uuid,
      purchaseOrderId: id.trim()
    }

    setLoadingApi(true)
    postVerifyPurchaseOrder(body).then(res => {
      const { resultData } = res.data
      
      userToken.current = resultData.userToken
      setVerifySuccess(true)
      fnPostPurchaseOrderDetail()
      setLoadingApi(false)
    }).catch(error => {
      if(!onNotiError)
      Noti.error({
        message: 'Error',
        description: error?.response?.data?.resultDescription,
        onCancel: () => {
          setOnNotiError(false)
          setLoadingApi(false)
        }
      })
      setOnNotiError(true)
      setPurchaseOrderId('')
    })
  }

  const fnPostExport = () => {
    let body = {
      purchaseOrderId: purchaseOrderId
    }
    postExport(
      { Authorization: `Bearer ${userToken.current}` },
      body
    ).then(res => {
      
      let fileName = `Report_TDS_${purchaseOrderId}_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`
      fileDownload(res.data, fileName)
    }).catch(error => {
      console.error(error)
      if(!onNotiError)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription,
          onCancel: () => {
            setOnNotiError(false)
          }
        })
      setOnNotiError(true)
    })
  }

  const fnPostSerialNumber = () => {
    setMacLogingApi(true)
    const serial = dataItem.map(x => x.serialNumber).filter(y => y)
    
    if (serial.length > 0) {
      postSerialNumber(
        { Authorization: `Bearer ${userToken.current}` },
        { serialNo: serial }
      ).then(res => {
        const { resData } = res.data
        
        let dt = dataItem
        const arrError = []
        dt = dt.map(r => {
          let f = resData.find((y) => r.serialNumber === y.serialNo)
          
          if (r.serialNumber) {
            
            if (f && f.mdmRegister) {
              r.warehouse = f.warehouse
              r.replacementBy = f.replacementBy
              r.deviceStatus = f.deviceStatus
              r.operationType = f.operationType
              r.wiFiMacAddress = f.wiFiMacAddress
              r.ethernetMacAddress = f.ethernetMacAddress
              r.deviceName = f.deviceName
              r.deviceId = f.deviceId
              r.modelName = f.modelName
              r.mdmRegister = f.mdmRegister
            } else {
              arrError.push(r.serialNumber)
              r.warehouse = ''
              r.replacementBy = ''
              r.deviceStatus = ''
              r.operationType = ''
              r.wiFiMacAddress = ''
              r.ethernetMacAddress = ''
              r.deviceName = ''
              r.deviceId = ''
              r.modelName = ''
              r.mdmRegister = ''
            }
          } else {
            r.warehouse = ''
            r.replacementBy = ''
            r.deviceStatus = ''
            r.operationType = ''
            r.wiFiMacAddress = ''
            r.ethernetMacAddress = ''
            r.deviceName = ''
            r.deviceId = ''
            r.modelName = ''
            r.mdmRegister = ''
          }
          return r
        })
        
        if (arrError.length) {
          Noti.warning({
            message: 'Warning',
            description: <>
              Serial number '{arrError.map(r => r).join(',')}' invalid.
            </>
          })
        }
        setDataItem(dt)
        setMacLogingApi(false)
      }).catch(error => {
        console.error(error)
        if(!onNotiError)
          if(error?.response?.data?.resultCode == "40304"){
            const resData = error?.response?.data?.resData || []
            let er = resData.map(x => {if(x.error) return x.serialNo})
            if(resData){
              let dt = dataItem
              dt = dt.map(r => {
                let f = resData.find((y) => r.serialNumber === y.serialNo)
                
                if (r.serialNumber) {
                  
                  if (f && f.mdmRegister) {
                    r.warehouse = f.warehouse
                    r.replacementBy = f.replacementBy
                    r.deviceStatus = f.deviceStatus
                    r.operationType = f.operationType
                    r.wiFiMacAddress = f.wiFiMacAddress ?? '-'
                    r.ethernetMacAddress = f.ethernetMacAddress ?? '-'
                    r.deviceName = f.deviceName
                    r.modelName = f.modelName
                    r.mdmRegister = f.mdmRegister
                  } else {
                    r.warehouse = ''
                    r.replacementBy = ''
                    r.deviceStatus = ''
                    r.operationType = ''
                    r.wiFiMacAddress = ''
                    r.ethernetMacAddress = ''
                    r.deviceName = ''
                    r.modelName = ''
                    r.mdmRegister = ''
                  }
                } else {
                  r.warehouse = ''
                  r.replacementBy = ''
                  r.deviceStatus = ''
                  r.operationType = ''
                  r.wiFiMacAddress = ''
                  r.ethernetMacAddress = ''
                  r.deviceName = ''
                  r.modelName = ''
                  r.mdmRegister = ''
                }
                return r
              })
              setDataItem(dt)
            }
            Noti.error({
              message: 'Warning',
              description: `Serial number '${er.filter(r => r).join(',')}' invalid.`,
              onCancel: () => {
                setOnNotiError(false)
              }
            })
          }else{
            Noti.error({
              message: 'Error',
              description: error?.response?.data?.resultDescription,
              onCancel: () => {
                setOnNotiError(false)
              }
            })
          }
        setOnNotiError(true)
        setMacLogingApi(false)
      })
    } else {
      setMacLogingApi(false)
    }
  }

  const fnPutUpdatePurchaseOrderDetail = () => {
    setLogingApi(true)
    const tempArr = []
    for (let i = 0; i < dataItem.length; i++) {
      let temp = dataItem[i]
      
      if (temp?.installationDate)
        temp.installationDate = dayjs(temp.installationDate).isValid() ? dayjs(temp.installationDate).$d : null

      if (temp?.deliveryDate)
        temp.deliveryDate = dayjs(temp.deliveryDate).isValid() ? dayjs(temp.deliveryDate).$d : null
        
      if (temp.serialNumber)
        tempArr.push(temp)
    }
    putUpdatePurchaseOrderDetail(
      { Authorization: `Bearer ${userToken.current}` },
      tempArr,
      uuid
    ).then(() => {
      Noti.success({
        message: 'Success',
        description: 'You data has been saved successfully.'
      })
      setEditMode(false)
      setLogingApi(false)
      fnPostPurchaseOrderDetail()
    }).catch(error => {
      console.log(error)
      if(!onNotiError)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription,
          onCancel: () => {
            setOnNotiError(false)
          }
        })
      setOnNotiError(true)
      setLogingApi(false)
    })
  }

  const disabledSave = () => {
    let isDisabled = false
    let count = 0
    for (let i = 0; i < dataItem.length; i++) {
      let item = dataItem[i]
      
      if (cloneItem.current[i].serialNumber) continue
      
      if (
        item.serialNumber &&
        ((item.installationDate != null && item.installationDate != undefined) && dayjs(item.installationDate).isValid()) &&
        ((item.deliveryDate != null && item.deliveryDate != undefined) && dayjs(item.deliveryDate).isValid()) &&
        item.packageTrackingNumber &&
        item.logisticCompany &&
        item.mdmRegister !== ''
      ) {
        count++
        continue
      }
      
      if (
        !item.serialNumber &&
        !((item.installationDate != null && item.installationDate != undefined) && dayjs(item.installationDate).isValid()) &&
        !((item.deliveryDate != null && item.deliveryDate != undefined) && dayjs(item.deliveryDate).isValid()) &&
        !item.packageTrackingNumber &&
        !item.logisticCompany &&
        !item.mdmRegister === ''
      ) {
        isDisabled = false
      } else {
        isDisabled = true
        break
      }
    }
    if (count >= 1 && !isDisabled) {
      isDisabled = false
    } else if (count >= 1 && isDisabled) {
      isDisabled = true
    }
    
    if (count === 0) isDisabled = true

    return isDisabled
  }

  const onSave = () => {
    fnPutUpdatePurchaseOrderDetail()
  }

  const dataItemTemp = dataItem.map((r, i) => {
    return { no: `${(i + 1)}`, ...r }
  })

  const _editMode = logingApi ? false : editMode

  const getRows = (rows) => [
    headerRow,
    ...rows.map((row, i) => (
    {
      rowId: i,
      height: HEADING_ROW_HEIGHT,
      cells: [
        {
          type: 'text',
          text: row?.no ?? '',          
          nonEditable: true,
          style: {
            background: '#eeeeee'
          }
        },
        {
          type: 'text',
          text: row?.part ?? '',          
          nonEditable: true,
          style: {
            background: '#eeeeee'
          }
        },
        {
          type: 'text',
          text: row?.serialNumber ?? '',          
          nonEditable: !_editMode || cloneItem.current[i].serialNumber ? true : false,
          style: _editMode && !cloneItem.current[i].serialNumber ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: 'scdatetime',
          value: row?.installationDate ??  '',
          nonEditable: !_editMode || cloneItem.current[i].installationDate ? true : false,
          style: _editMode && !cloneItem.current[i].installationDate ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: 'scdatetime',
          value: row?.deliveryDate ??  '',
          nonEditable: !_editMode || cloneItem.current[i].deliveryDate ? true : false,
          style: _editMode && !cloneItem.current[i].deliveryDate ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: 'text',
          text: row?.packageTrackingNumber ?? '',
          nonEditable: !_editMode || cloneItem.current[i].packageTrackingNumber ? true : false,
          style: _editMode && !cloneItem.current[i].packageTrackingNumber ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: 'scdropdown',
          value: row?.logisticCompany ?? '',
          values: [
            { value: '', label: '' },
            { value: 'DHL', label: 'DHL' },
            { value: 'Other', label: 'Other' }
          ],
          isOpen: row?.isOpen,
          nonEditable: !_editMode || cloneItem.current[i].logisticCompany ? true : false,
          style: _editMode && !cloneItem.current[i].logisticCompany ? true : false ? {} : {
            background: '#eeeeee'
          }
        },
        {
          type: 'text',
          text: row?.mdmRegister ?? '',
          nonEditable: true,
          style: {
            background: '#eeeeee'
          }
        },
        {
          type: 'text',
          text: row?.ethernetMacAddress ?? '',
          nonEditable: true,
          style: {
            background: '#eeeeee'
          }
        },
        {
          type: 'text',
          text: row?.wiFiMacAddress ?? '',
          nonEditable: true,
          style: {
            background: '#eeeeee'
          }
        }
      ]
    })),
  ]

  const rows = getRows(dataItemTemp)

  const columns = getColumns()

  const handleChanges = (changes) => {
    console.log("handleChanges", changes)
    let isDup = false
    for (let i = 0; i < changes.length; i++) {
      
      // if (changes[i].columnId === 'deliveryDate' || changes[i].columnId === 'installationDate') {
      //   changes[i].newCell.value = dayjs(changes[i].newCell.date).format('.format("YYYY-MM-DD HH:mm")')
      //   
      //   if (!changes[i].newCell.value) {
      //     changes[i].newCell.date = ''
      //   }
      // }
      
      if ((changes[i].columnId === 'serialNumber' || changes[i].columnId === 'packageTrackingNumber') && changes[i].newCell.text) {
        changes[i].newCell.text = changes[i].newCell.text.replaceAll('\r', '')
        
        if (changes[i].columnId === 'serialNumber') {
          let f = dataItem.find((x) => (x && x.serialNumber) === changes[i].newCell.text)
          
          if (f) {
            changes[i].newCell.text = changes[i].previousCell.text
            changes[i].newCell.value = changes[i].previousCell.text
            if(isDup) continue
            if(!onNotiError)
              Noti.error({
                message: 'Error',
                description: 'Serial Number is duplicated',
                onCancel: () => {
                  setOnNotiError(false)
                }
              })
            isDup = true
            setOnNotiError(true)
          }
        }
      }
    }
    setDataItem((prevData) => applyChangesToData(changes, prevData))
  }

  return (
    <Layout activeKey='tds-saas-unit-delivery'>
      <Helmet>
        <title>[NodesNow] Unit Delivery</title>
      </Helmet>
      {
        loadingFirst
          ? <SkeletonLoading />
          : <>
            <Card title="NodesNow Unit Delivery" style={{ textAlign: 'left' }}>
              <Form
                form={form}
                onFinish={(value) => {
                  fnPostVerifyPurchaseOrder(purchaseOrderId)
                }}
                initialValues={{}}
                size={'default'}
                labelCol={{ flex: '150px' }}
                labelAlign='left'
                labelWrap
                wrapperCol={{ flex: 1 }}
                colon={false}
              >
                <Row gutter={8}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-style'>
                    <Form.Item
                      label="Purchase Order ID* :"
                      name='pdId'
                      rules={[]}
                    >
                      <div style={{ display: 'flex', gap: '10px' }}>
                        {/* <label for='txtPoid' style={{ display: 'none' }}>xxx</label> */}
                        <Input
                          style={{ flex: 5 }}
                          value={purchaseOrderId}
                          onChange={(e) => {
                            setPurchaseOrderId(e.target.value)
                          }}
                          disabled={loadingApi || verifySuccess}
                          data-testid='txtPoid'
                          name='pdId'
                        />
                        <Button type='primary' style={{ minWidth: 185 }} className='ant-btn-middle'
                          onClick={() => {
                            form.submit()
                          }}
                          loading={loadingApi}
                          disabled={loadingApi || verifySuccess || !purchaseOrderId}
                          data-testid='submit'
                        >Search</Button>
                      </div>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <div style={{ display: 'none' }}>
                <Button data-testid='test1' onClick={() => {
                  let dt = dataItem
                  dt = dt.map(r => {
                    r.serialNumber = 'NJ23T000004'
                    return r
                  })
                  setDataItem(dt)
                }}> </Button>
              </div>
              {
                verifySuccess
                  ? <Row gutter={8}>
                    {
                      editMode
                        ? <Col
                          xs={24} sm={24} md={24} lg={24} xl={24}
                          className='col-style'
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            marginTop: '1rem'
                          }}
                        >
                          <div>
                            <Button type='primary' icon={<CloudSyncOutlined />}
                              data-testid='get-mac'
                              onClick={() => {
                                fnPostSerialNumber()
                              }} style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                              disabled={logingApi}
                              loading={logingMacApi}
                              className='bt-main'>Get Mac Address</Button>
                            <Button type='primary' icon={<SaveOutlined />}
                              data-testid='save'
                              onClick={() => {
                                onSave()
                              }} style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                              disabled={logingMacApi || disabledSave()}
                              loading={logingApi}
                              className='bt-main'>Save</Button>
                            <Button icon={<CloseOutlined />}
                              data-testid='cancel'
                              onClick={() => {
                                setEditMode(false)
                                fnPostPurchaseOrderDetail()
                              }}
                              style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                              disabled={logingApi || logingMacApi}
                              className='bt-main'
                            >Cancel</Button>
                          </div>
                        </Col>
                        : <Col
                          xs={24} sm={24} md={24} lg={24} xl={24}
                          className='col-style'
                          style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            marginTop: '1rem'
                          }}
                        >
                          <div>
                            {
                              !hideEdit
                                ? <>
                                  <Button type='primary' icon={<EditOutlined />}
                                    data-testid='edit'
                                    onClick={() => {
                                      setEditMode(true)
                                    }}
                                    style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                                    disabled={logingApi}
                                    className='bt-main'
                                  >Edit</Button>
                                </>
                                : <>
                                <Button type='primary' icon={<DownloadOutlined />}
                                  data-testid='download'
                                  onClick={() => {
                                    fnPostExport()
                                  }}
                                  style={{ marginLeft: 4, minWidth: 150, marginTop: 4 }}
                                  disabled={logingApi}
                                  className='bt-main'
                                >Export File</Button>
                                </>
                            }
                          </div>
                        </Col>
                    }
                  </Row>
                  : <div style={{ display: 'none' }}> not verify </div>
              }
              {
                verifySuccess
                  ? <Card
                    title=''
                    bordered={false}
                    style={{ width: '100%', marginTop: 10 }}
                    className='table-list'
                  >
                    <div style={{
                      display: 'flex',
                      gap: '.5rem',
                      marginBottom: '.5rem'
                    }}>
                      <label>Total unit amount: {dataItem.length} units</label>
                      {editMode && <Tooltip
                        title='You can copy information from excel and paste to this table.'
                        placement='right'
                        overlayInnerStyle={{
                          color: '#000',
                          padding: '1rem',
                          width: '500px'
                        }}
                        color='#ffffff'
                      >
                        <ExclamationCircleOutlined />
                      </Tooltip>
                      }
                    </div>
                    <div style={{
                      display: 'flex',
                      width: '100%',
                      height: '500px',
                      overflow: 'auto'
                    }}>
                      {
                        !logingApi
                          ? <ReactGrid
                            stickyLeftColumns={2}
                            stickyTopRows={1}
                            enableRangeSelection={true}
                            customCellTemplates={{
                              'scdropdown': new GridDropdownCellTemplate(),
                              'scdatetime': new GridDateTimeCellTemplate(),
                            }}
                            enableFillHandle={editMode}
                            onCellsChanged={handleChanges}
                            rows={rows}
                            columns={columns}
                          />
                          : <Skeleton active />
                      }
                    </div>
                  </Card>
                  : <div style={{ display: 'none' }}>not verify</div>
              }
            </Card>
          </>
      }
    </Layout >
  )
}

export default App