/* istanbul ignore file */
const initialState = {
  purchaseOrders : [],
  installation:false
};

const purchaseOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_PO":
      return {
        ...state,
        purchaseOrders: [...state.purchaseOrders, action.payload],
      };
    case "DELETE_PO":
      return {
        ...state,
        purchaseOrders: state.purchaseOrders.filter((task) => task.id !== action.payload),
      };
    case "ADD_INS":
      return {
        ...state,
        installation: action.payload,
      };
    case "DELETE_ALL_INS":
      return {
        ...state,
        installation: false,
      };
    default:
      return state;
  }
};

export default purchaseOrderReducer;
