import React, { useEffect, useState, useRef } from "react";
import { Divider, Button, Table, Skeleton, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import { getList } from "../../services/Investigation";
import { jwtDecode } from "jwt-decode";
import IconDocument from "../../assets/ic-document.png";
import Notification from "../../services/Notification";

// import TableLoading from '../../component/table/Loading'
// import _ from 'lodash'

const App = (props) => {
  const initialized = useRef(false);
  const [dataComplete, setDataComplete] = useState([]);
  const [dataReplacement, setDataReplacement] = useState([]);
  const [repSeq, setRepSeq] = useState("");
  const dataList = useRef([]);
  const [loadingList, setLoadingList] = useState(false);
  const [rma, setRma] = useState(props?.rma);
  const Noti = new Notification();
  const { token } = useParams();
  const { investigateId, poToShyftId } = jwtDecode(props?.token ?? token);

  useEffect(
    /* istanbul ignore next */ () => {
      /* istanbul ignore else */
      if (!initialized.current) {
        initialized.current = true;
        load();
      }
    }
  );

  const load = async () => {
    setLoadingList(true);
    try {
      await fnGetList();
    } catch (error) {
      console.error(error);
      setLoadingList(false);
    }
  };

  const fnGetList = () => {
    return new Promise((resolve, reject) => {
      getList({}, poToShyftId, { Authorization: `Bearer ${token}` })
        .then((res) => {
          let { resultData } = res?.data;
          dataList.current = resultData.poToShyftLineList ?? [];
          let done = dataList.current
            .filter((y) => y.status.toLowerCase() === "completed")
            .map((item, index) => ({ ...item, no: index + 1 }));
          let replace = dataList.current
            .filter(
              (y) => y.status.toLowerCase() === "request unit replacement"
            )
            .map((item, index) => ({ ...item, no: index + 1 }));
          let rSeq = replace.find((x) => x.newRepSeq)?.newRepSeq;
          setDataComplete(done);
          setDataReplacement(replace);
          setRepSeq(rSeq);
          // if(replace && replace.length > 0 && replace[0]?.unitReplacementId){
          //   getUnitReplacement({}, replace[0]?.unitReplacementId).then((res2) =>{
          //     if(res2 && res2.data && res2.data?.resData)
          //       setRma(res2.data.resData)
          //     setLoadingList(false)
          //     resolve()
          //   }).catch((error) => {
          //     console.error(error)
          //     Noti.error({
          //       message: 'Error',
          //       description: error?.response?.data?.resultDescription
          //     })
          //     setLoadingList(false)
          //     reject()
          //   })
          // }else{
          setLoadingList(false);
          resolve();
          // }
        })
        .catch((error) => {
          console.error(error);
          Noti.error({
            message: "Error",
            description: error?.response?.data?.resultDescription,
          });
          setLoadingList(false);
          reject();
        });
    });
  };

  return (
    <>
      <Divider plain></Divider>
      {loadingList &&
        [{}, {}, {}].map((r, i) => {
          return (
            <div className={`card-li card`} key={"loadingList_" + i}>
              <Row>
                <Col flex="70px" style={{ display: "flex" }}>
                  <Skeleton.Button
                    active
                    shape={"circle"}
                    size={"large"}
                    style={{ margin: "auto" }}
                  />
                </Col>
                <Col flex="auto">
                  <Skeleton.Button active={true} block={true} size={"small"} />
                  <Skeleton.Button
                    active={true}
                    block={true}
                    size={"small"}
                    style={{ marginTop: 8 }}
                  />
                </Col>
              </Row>
            </div>
          );
        })}
      {!loadingList && (
        <>
          {dataComplete.length > 0 && (
            <>
              <h4>Investigation Completed</h4>
              <Table
                key={"1"}
                rowKey={(record) => record.key}
                bordered
                showSorterTooltip={false}
                scroll={{ x: 300 }}
                columns={[
                  {
                    title: "No.",
                    dataIndex: null,
                    align: "left",
                    key: null,
                    sorter: false,
                    width: 100,
                    render: (data) => <>{data.no}</>,
                  },
                  {
                    title: "Serial Number",
                    dataIndex: null,
                    align: "left",
                    key: null,
                    sorter: false,
                    render: (data) => <>{data.serialNumber}</>,
                  },
                ]}
                locale={{ emptyText: <div> No Data </div> }}
                pagination={false}
                dataSource={dataComplete}
                size="small"
              />
            </>
          )}

          {dataReplacement.length > 0 && (
            <>
              <h4>Request Unit Replacement: {repSeq}</h4>
              <Table
                key={"1"}
                rowKey={(record) => record.key}
                bordered
                showSorterTooltip={false}
                scroll={{ x: 300 }}
                columns={[
                  {
                    title: "No.",
                    dataIndex: null,
                    align: "left",
                    key: null,
                    sorter: false,
                    width: 100,
                    render: (data) => <>{data.no}</>,
                  },
                  {
                    title: "Serial Number",
                    dataIndex: null,
                    align: "left",
                    key: null,
                    sorter: false,
                    render: (data) => <>{data.serialNumber}</>,
                  },
                ]}
                locale={{ emptyText: <div> No Data </div> }}
                pagination={false}
                dataSource={dataReplacement}
                size="small"
              />
              {rma?.RMAFileName && (
                <>
                  <h4>RMA Document :</h4>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={IconDocument}
                      alt=""
                      style={{ width: 24, height: 24 }}
                    />
                    <span
                      style={{
                        textDecoration: "underline",
                        color: "#116BEA",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(
                          rma?.RMAFile,
                          "_blank",
                          "noopener,noreferrer"
                        );
                      }}
                    >
                      {rma?.RMAFileName || "RMA-xxxxxxx.pdf"}
                    </span>
                  </div>
                </>
              )}
            </>
          )}

          {/* {dataComplete.length > 0 && */}
          <div
            style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
          >
            <Button
              type="primary"
              block
              data-testid="installationCompleted"
              onClick={() => {
                props.onAcceptance();
              }}
              loading={loadingList}
              // onClick={() => { onClickInstallationCompleted() }}
              // disabled={
              //   dataList.length === 0 ||
              //   loadingList ||
              //   !_.every(dataList, { status: 'Checklist Done' })
              // }
            >
              Acceptance Document
            </Button>
          </div>
          {/* } */}

          <div
            style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
          >
            <Button
              type="default"
              block
              data-testid="back"
              onClick={() => {
                props.onBack();
              }}
              loading={loadingList}
            >
              Back
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default App;
