// DateTimeCellTemplate.tsx
import {useState,useMemo,useRef,useEffect} from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs'

export class GridDateTimeCellTemplate {
  getCompatibleCell(uncertainCell){
    // console.log("getCompatibleCell", uncertainCell)
    const value = (uncertainCell.value != null && uncertainCell.value != undefined) && dayjs(uncertainCell.value).isValid() ? dayjs(uncertainCell.value) : undefined;
    return { ...uncertainCell, value };
  }

  update(cell, cellToMerge) {
    let dd = (cellToMerge.value != null && cellToMerge.value != undefined) && dayjs(cellToMerge.value).isValid() ? dayjs(cellToMerge.value) : undefined
    return { ...cell, value: dd };
  }

  render(cell, isInEditMode, onCellChanged) {
      // console.log("render cell", cell)
      return <div className='scdropdown'>
            <DateTimeInput onCellChanged={(cell) => {
                onCellChanged(this.getCompatibleCell(cell), true)
            }} cell={cell} />
        </div>
  }
}

const DateTimeInput = ({ onCellChanged, cell }) => {

    const selectRef = useRef(null)

    const [inputValue, setInputValue] = useState(cell.inputValue)
    const [isOpen, setIsOpen] = useState(false)
    const [isFocus, setIsFocus] = useState(false)
    // const selectedValue = useMemo(() => cell.date.find(val => val.value === cell.text), [cell.text, cell.values])

    // useEffect(() => {
    //     if (cell.isOpen && selectRef.current) {
    //         selectRef.current.focus()
    //         setInputValue(cell.inputValue)
    //     }
    // }, [cell.isOpen, cell.inputValue])

    useEffect(() => {
      if(cell.value != inputValue){
        let dd = (cell.value != null && cell.value != undefined) && dayjs(cell.value).isValid() ? dayjs(cell.value) : undefined
        onCellChanged({ ...cell, value: dd,}, true);
        setInputValue(dd)
      }
    }, [cell.value]);

    return <>
      <div
        style={{ width: '100%' }}
        onPointerDown={(e) => {if(cell.nonEditable)return;e.stopPropagation();setIsOpen(true)}}
      >
        <DatePicker
          showTime
          // defaultValue={dayjs(cell.date).isValid() ? dayjs(cell.date) : undefined}
          onChange={(dateString) => {
            dateString = (dateString != null && dateString != undefined) && dayjs(dateString).isValid() ? dayjs(dateString) : undefined
            onCellChanged({ ...cell, value:dateString}, true);
            setIsOpen(false)
          }}
          
          disabled={cell.nonEditable}
          disabledDate={(current)=> current && current < new Date()}
          open={isOpen}
          onOk={()=>{setIsOpen(false)}}
          value={inputValue}
          format='MMM DD, YYYY HH:mm'
          style={{height:"39px", width:"210px",
          border:"none", boxShadow:"none"
        }}
          // onOpenChange={(e)=>  e ? setIsOpen(true) : console.log(e)}
          focus={isOpen}
          onFocus={(e)=>{ setIsOpen(true)}}
          onBlur={(e)=>{ setIsOpen(false)}}
        />
    </div >
    </>
}