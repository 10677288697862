/* istanbul ignore file */
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Helmet } from 'react-helmet'
import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
  Spin
} from 'antd'
import {
  EyeOutlined,
  MailOutlined
} from '@ant-design/icons'
import _ from 'lodash'

import Layout from '../../component/layout/Layout'
import SkeletonLoading from '../../component/SkeletonLoading'
import { Columns } from '../../component/table/AssignUnitInstallation'
import TableLoading from '../../component/table/Loading'
import {
  postPurchaseOrderDetail,
  postSendAssignedToShyft
} from '../../services/AssignUnitInstallation'
import { postVerifyPurchaseOrder } from '../../services/TdsSaasUnitDelivery'
import { itemRender } from '../../utils/common'
import Notification from '../../services/Notification'

const App = (props) => {
  const [form] = Form.useForm()
  const [formModal] = Form.useForm()
  const initialized = useRef(false)
  const [loadingFirst, setLoadingFirst] = useState(true)
  const [loadingApi, setLoadingApi] = useState(false)
  const [disableEmail, setDisableEmail] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setModalData] = useState(null)
  const [dataList, setDataList] = useState([])
  const { uuid } = useParams()
  let { poId } = useParams()
  const navigate = useNavigate()
  const [token, setToken] = useState(null)
  const Noti = new Notification()

  poId = atob(props?.poId ?? poId)

  const itemMenu = [
    {
      href: '/assign-unit-installation',
      title: (
        <>
          <span>Assign Unit Installation</span>
        </>
      )
    }
  ]

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (!initialized.current) {
      initialized.current = true
      onload()
    }
  })

  useEffect(/* istanbul ignore next */() => {
    /* istanbul ignore else */
    if (token)
      fnPostPurchaseOrderDetail()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const onload = async () => {
    try {
      await fnPostVerifyPurchaseOrder()
    } catch (error) { }
  }

  const fnPostVerifyPurchaseOrder = /* istanbul ignore next */() => {
    return new Promise((resolve, reject) => {
      let body = {
        uuid: uuid,
        purchaseOrderId: poId
      }
      
      postVerifyPurchaseOrder(body)
        .then(res => {
          const { resultData } = res.data
          setToken(resultData.userToken)
          resolve()
        })
        .catch(error => {
          console.error(error)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
          reject()
        })

    })
  }

  const fnPostPurchaseOrderDetail = () => {
    setLoadingFirst(false)
    setLoadingApi(true)
    return new Promise((resolve, reject) => {
      postPurchaseOrderDetail(
        { Authorization: `Bearer ${token}` },
        {},
        uuid
      ).then(res => {
        let { resData } = res.data
        const rawData = resData?.customer_delivery ?? []
        let newData = []
        rawData.forEach(r => {
          r?.unit_delivery.forEach(rr => {
            newData.push({
              ...rr,
              customerDeliveryId: r?.customer_delivery_id ?? null,
              latestEmailSentOn: r?.latestEmailSentOn ?? null
            })
          })
        })
        let group = []
        /* istanbul ignore else */
        if (newData?.length > 0) {
          newData = newData.filter(r => r.packageTrackingNumber)
          let counts = _.countBy(newData, 'packageTrackingNumber')
          group = _
            .uniqBy(newData, 'packageTrackingNumber')
            .map(g => { g.childs = []; return g })
          const _newData = _.cloneDeep(newData)
          for (let i = 0; i < _newData.length; i++) {
            const data = _newData[i]
            const findIndex = Object.keys(counts).findIndex(c => c === data.packageTrackingNumber)
            /* istanbul ignore else */
            if (findIndex > -1) {
              group[findIndex].childs.push(data)
            }
          }
        }
        group = group.map((g, i) => {
          g.seqNo = i + 1
          g.qty = g?.childs?.length
          return g
        })
        setDataList(group)
        setLoadingApi(false)
        resolve()
      }).catch(error => {
        console.error(error)
        setLoadingApi(false)
        Noti.error({
          message: 'Error',
          description: error?.response?.data?.resultDescription
        })
        reject()
      })
    })
  }

  const fnPostSendAssignedToShyft = (data) => {
    setLoadingApi(true)
    const assignedTo = formModal.getFieldValue('email')

    const body = {
      packageTrackingNumber: data.packageTrackingNumber,
      assignedTo: assignedTo,
      customerDeliveryId: data.customerDeliveryId,
      purchaseOrderId: poId
    }

    
    return new Promise((resolve, reject) => {
      postSendAssignedToShyft(
        { Authorization: `Bearer ${token}` },
        body
      )
        .then(res => {
          setLoadingApi(false)
          Noti.success({
            message: 'Success',
            description: `Your email to ${assignedTo} has been sent successfully.`
          })
          resolve()
        })
        .catch(error => {
          console.error(error)
          setLoadingApi(false)
          Noti.error({
            message: 'Error',
            description: `We encountered an issue while attempting to send the email. Please verify the recipient's email address and try again.`
          })
          reject()
        })
    })
  }

  const sendEmailToShyft = async (data) => {
    try {
      setDisableEmail(true)
      await fnPostSendAssignedToShyft(data)
      await fnPostPurchaseOrderDetail()
      setIsModalOpen(false)
      setDisableEmail(false)
    } catch (error) {
      console.error(error)
      setDisableEmail(false)
    }
  }

  const onClickMail = (data) => {
    formModal.resetFields()
    setModalData(data)
    setIsModalOpen(true)
  }

  const onClickView = (data) => {
    navigate(`/assign-unit-installation/${uuid}/${btoa(poId)}/${data.packageTrackingNumber}`, { state: { ...data, token } })
  }

  return (<>
    <Layout activeKey='assign-unit-installation'>
      <Helmet>
        <title>[NodesNow] Unit Delivery</title>
      </Helmet>
      <Breadcrumb
        itemRender={itemRender}
        items={itemMenu}
      />
      <Divider plain></Divider>
      {loadingFirst ? <SkeletonLoading /> : <>
        <Card title='Assign Unit Installation' style={{ textAlign: 'left' }}>
          <Form
            form={form}
            onFinish={(value) => {
            }}
            initialValues={{}}
            size={'default'}
            labelCol={{ flex: '150px' }}
            labelAlign='left'
            labelWrap
            wrapperCol={{ flex: 1 }}
            colon={false}
          >
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-style'>
                <Form.Item
                  label='Purchase Order ID* :'
                  name='pdId'
                  rules={[]}
                  colon={true}
                >
                  {poId}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            key={'1'}
            rowKey={record => record.key}
            bordered
            showSorterTooltip={false}
            scroll={{ x: 300 }}
            columns={[
              ...Columns,
              {
                title: 'Assign To',
                dataIndex: null,
                align: 'center',
                key: null,
                sorter: false,
                width: 100,
                render: (data) => <>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '.5rem'
                  }}>
                    <Tooltip title='To assign the task to on-site staff.'>
                      <Button
                        shape='circle'
                        className='ant-btn-circle-icon'
                        icon={<MailOutlined />}
                        style={{ padding: '0px 6px' }}
                        onClick={() => onClickMail(data)}
                        data-testid='btn-mail'
                      />
                    </Tooltip>
                    <Tooltip title='To view the unit list under this package tracking number.'>
                      <Button
                        shape='circle'
                        className='ant-btn-circle-icon'
                        icon={<EyeOutlined />}
                        style={{ padding: '0px 6px' }}
                        onClick={() => onClickView(data)}
                        data-testid='btn-view'
                      />
                    </Tooltip>
                  </div>
                </>
              }
            ]}
            loading={{
              spinning: loadingApi,
              indicator: <TableLoading />
            }}
            locale={{ emptyText: <div > No Data </div> }}
            pagination={false}
            dataSource={dataList}
            size='small'
          />
        </Card>
      </>}

    </Layout>
    <Modal
      title='Confirmation'
      open={isModalOpen}
      centered
      width={800}
      footer={null}
      closeIcon={false}
    >
      
      <Form
        form={formModal}
        onFinish={() => sendEmailToShyft(modalData)}
      >
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '.5rem'
        }}>
          <Row>
            <span style={{ fontSize: '16px' }}>
              Please assign on-site installation staff e-mail as below.
            </span>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className='col-style'>
              <Form.Item
                label='Email: '
                name='email'
                rules={[{
                  required: true,
                  message: 'Enter a valid email address.',
                  type: 'email'
                }]}
              >
                <Input
                  disabled={disableEmail}
                  placeholder='shyft-on-site@nodesnow.com'
                  style={{
                    maxWidth: '650px'
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <span style={{ fontSize: '16px' }}>
              When you press the “Send” button, the installation information will be sent to the recipient’s e-mail.
            </span>
            <span style={{ fontSize: '16px' }}>
              Tracking Number: {modalData?.packageTrackingNumber}
            </span>
          </Row>
        </div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          gap: '1rem'
        }}>
          <Button
            disabled={disableEmail}
            type='primary'
            htmlType='submit'
          >Send</Button>
          <Button
            disabled={disableEmail}
            type='primary'
            onClick={() => setIsModalOpen(false)}
          >Cancel</Button>
        </div>

      </Form>
    </Modal>
    {disableEmail ? <>
      <Spin className='spin-loading'></Spin>
    </> : <></>}
  </>)
}

export default App