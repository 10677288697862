import React, { useEffect, useState, useRef } from 'react'
import {
  Form,
  Modal,
  Row,
  Col,
  Divider,
    Breadcrumb,
    Skeleton,
  Button
} from 'antd'
import FloatInput from '../../component/FloatInput'
import logoNN from '../../assets/nn1.png'
import arrow from '../../assets/arrow.svg'
import scanIcon from '../../assets/scan-qrcode.png'
import checkIcon from '../../assets/check.png'
import cancelIcon from '../../assets/cancel.png'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getList } from '../../services/Warehouse'
import Notification from '../../services/Notification'
import { SearchOutlined } from '@ant-design/icons'
import { itemRender } from '../../utils/common'
import { Helmet } from 'react-helmet'
import { jwtDecode } from 'jwt-decode'
import WarehouseAcceptance from './warehouse-acceptance'
import WarehouseReplacement from './warehouse-unit-replacement'
import _ from 'lodash'
import {
  postAcceptanceDetail
} from '../../services/Investigation'
import dayjs from 'dayjs'
import InstallationPreview from './warehouse-preview'

const p1 = `The installation is completed. To validate this process, the client must sign the document in the next step. Please provide the information to proceed.`
const p2 = `The installation is complete. The Acceptance document has been signed and received.`
const p3 = `The transportation is completed. To validate this process, the client must sign the document in the next step. Please provide the information to proceed.`
const p4 = `After the device has been received, to validate this process, the client must sign the document in the next step. Please provide the information to proceed.`
const statusObj = {
  2: 'Planned',
  3: 'Delivering from WH to Cust.',
  9: 'Reserved',
  10: 'At Cust. site',
  11: 'Unit installation completed',
  12: 'Acceptance Document generated',
  13: 'Acceptance Document signed',
  14: 'Installation & Orientation completed'
}

const App = (props) => {
  const [form] = Form.useForm()
  const initialized = useRef(false)
  const [cloneList, setCloneList] = useState([])
  const [dataList, setDataList] = useState([])
  const navigate = useNavigate()
  const [loadingList, setLoadingList] = useState(false)
  const [showDeliAccep, setShowDeliAccep] = useState(false)
  const [showUnitReplacement, setShowUnitReplacement] = useState(true)
  const [showPreview, setShowPreview] = useState(false)
  const [pdf, setPdf] = useState(null)
  const [isView, setIsView] = useState(false)
  const [isGen, setIsGen] = useState(false)
  const [isSigned, setIsSigned] = useState(false)
  const [viewData, setViewData] = useState(null)
  const [status, setStatus] = useState(null)
  const [values, setValues] = useState(null)
  const [rmaData, setRmaData] = useState([])
  const [seq, setSeq] = useState("")
  const { token } = useParams()
  const Noti = new Notification()
  const { rmaId, actionType } = jwtDecode(props?.token ?? token)

  useEffect(() => {
    if (!initialized.current) {
      onLoad()
      initialized.current = true
    }
  })

  const onLoad = async () => {
    setLoadingList(true)
    try {
      // const getCustomerDeliveryRes = await fnGetCustomerDelivery()
      // setStatus(getCustomerDeliveryRes?.status)
      // if (statusObj[getCustomerDeliveryRes?.status] !== statusObj[14]) {
      //   if (statusObj[getCustomerDeliveryRes.status] === statusObj[12]) {
      //     setIsGen(true)
      //   }
      //   if (statusObj[getCustomerDeliveryRes.status] === statusObj[13]) {
      //     setIsSigned(true)
      //   }
      //   if (statusObj[getCustomerDeliveryRes?.status] !== statusObj[2] &&
      //     statusObj[getCustomerDeliveryRes?.status] !== statusObj[3] &&
      //     statusObj[getCustomerDeliveryRes?.status] !== statusObj[9]) {
      // await fnGetList()
      //   }
      // }
      setLoadingList(false)
    } catch (error) {
      console.error(error)
      setLoadingList(false)
    }
  }

  const onShowPreview = (data) => {
    setPdf(`${data?.file?.url}?time=${dayjs().format('YYYYMMDDHHmmss')}`)
    setValues(data?.values)
    setShowDeliAccep(false)
    setShowPreview(true)
  }

  const onClickView = async (data = {}) => {
    try {
      setLoadingList(true)
      const getAcceptance = await fnGetAcceptance()
      setViewData({
        ...getAcceptance,
      })
      setLoadingList(false)
      setShowPreview(false)
      setShowDeliAccep(true)
      setIsView(data?.isView === undefined ? false : true)
    } catch (error) {
      setLoadingList(false)
      console.error(error)
    }
  }

  const fnGetAcceptance = () => {
    return new Promise((resolve, reject) => {
      let searchId = {
        rmaId : rmaId
      }
      postAcceptanceDetail({ Authorization: `Bearer ${token}` }, searchId)
        .then((res) => {
          const { resData } = res.data
          resolve(resData)
        }).catch((error) => {
          console.error(error)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
          reject()
        })
    })
  }

  const onSendToSign = () => {
    setShowPreview(false)
    setShowUnitReplacement(true)
  }

  return (<>
    <Helmet>
      <title>[NodesNow] Warehouse Partner</title>
    </Helmet>
    <div className='main-container'>
      <div className='public-form'>
        {!showPreview && <>
          <div className='card-main'>
            <Breadcrumb
              itemRender={itemRender}
              items={[
                {
                  href: '/warehouse-partner',
                  title: (
                    <>
                      <span>Warehouse Partner</span>
                    </>
                  ),
                },
              ]}
            />
            <Divider plain></Divider>
            <div className='logo-container'>
              <img src={logoNN} className='img-logo' alt=''></img>
            </div>
              {showUnitReplacement && <>
                <WarehouseReplacement
                  rma={rmaData}
                  seq={(e)=>{setSeq(e)}}
                  onAcceptance={() => {
                    setShowUnitReplacement(false)
                    onClickView({ isView: true })
                  }}
                />
              </>}

              {showDeliAccep && <>
                <WarehouseAcceptance
                  token={props?.token ?? token}
                  onShowPreview={onShowPreview}
                  isView={isView}
                  isGen={isGen}
                  isSigned={isSigned}
                  viewData={viewData}
                  seq={seq}
                  onBack={() => {
                    setShowDeliAccep(false)
                    setShowUnitReplacement(true)
                  }}
                  p={actionType == 2 ? p3 : p4 }
                />
              </>}
              
          </div>
        </>}
        {showPreview && <>
          <InstallationPreview
            pdf={pdf}
            values={values}
            onClickView={onClickView}
            onSendToSign={onSendToSign}
            isGen={isGen}
            isSigned={isSigned}
          />
        </>}
      </div>
    </div>
  </>)
}

export default App