/* istanbul ignore file */
import React, { useState } from 'react'
import { Input } from 'antd'
import './index.css'

const FloatInput = (props) => {
  const [focus, setFocus] = useState(false)
  let { label, value, placeholder } = props

  if (!placeholder) placeholder = label

  const isOccupied = focus || (value && value.length !== 0)

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder'

  let props_ = { ...props }
  delete props_['placeholderInput']
  delete props_['focus']
  return (
    <div
      className='float-label'
      onBlur={() => {
        setFocus(false)
      }}
      data-testid='input-id'
      onFocus={() => setFocus(true)}
    >
      <Input
        {...props_}
        onChange={props.onChange}
        placeholder={focus || props.focus ? props.placeholderInput : ''}
      />
      <label htmlFor={props.id} className={`${labelClass} ${props.disabled ? 'disabled' : ''}`}>
        {props.required ? <span style={{ color: '#D1333D' }}> * </span> : <span style={{ color: '#D1333D' }}>  </span>} {isOccupied ? label : placeholder}
      </label>
    </div>
  )
}

export default FloatInput