/* istanbul ignore file */
import { post, put, get } from './RequestApi'

export function getList(params, id, headers) {
  return get({ url: `/installation-partner/${id}`, params, headers })
}

export function getDetail(data, id, headers) {
  return get({ url: `/installation-partner/detail/${id}`, data, headers })
}

export function getDetailTree(data, id, headers) {
  return get({ url: `/installation-partner/checklist/${id}`, data, headers })
}

export function postUpdateStatus(data, headers) {
  return post({ url: `/installation-partner/status`, data, headers })
}

export function putUpdateComment(data, id, headers) {
  return put({ url: `/installation-partner/comment/${id}`, data, headers })
}

export function postUpdateStatusCustomerDelivery(headers, data) {
  return post({ url: `/installation-partner/po-to-shyft/status`, headers, data })
}

export function postAcceptanceDetail(headers, data) {
  return post({ url: `/acceptance/detail`, headers, data })
}

export function postAcceptance(headers, data) {
  return post({ url: `/acceptance`, headers, data })
}

export function postGenerateAcceptance(headers, data) {
  return post({ url: `/acceptance/generate`, headers, data })
}

export function postPreviewAcceptance(headers, data) {
  return post({ url: `/acceptance/preview`, headers, data })
}

export function postSendAcceptance(headers, data) {
  return post({ url: `/acceptance/send`, headers, data })
}

export function getDropdownInvestigate(params, headers) {
  return get({ url: `/installation-partner/investigate/dropdownlist`, params, headers })
}