import dayjs from "dayjs"

/* istanbul ignore file */
const Columns = [
  {
    title: 'No.',
    dataIndex: 'seqNo',
    align: 'center',
    key: 'seqNo',
    sorter: false,
    width: 40,
    render: (text) => <div className="font16 px-text-center">{text}</div>
  },
  {
    title: 'Package Tracking Number',
    dataIndex: 'packageTrackingNumber',
    key: 'packageTrackingNumber',
    sorter: false,
    width: 230,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'QTY',
    dataIndex: 'qty',
    key: 'qty',
    sorter: false,
    width: 40,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'Installation Date',
    dataIndex: 'installationDate',
    key: 'installationDate',
    sorter: false,
    width: 180,
    render: (data) => <span className="font16">{data ? dayjs(data).format('MMM-DD, YYYY HH:mm') : null}</span>
  },
  {
    title: 'Delivery Date',
    dataIndex: 'deliveryDate',
    key: 'deliveryDate',
    sorter: false,
    width: 180,
    render: (data) => <span className="font16">{data ? dayjs(data).format('MMM-DD, YYYY HH:mm') : null}</span>
  },
  {
    title: 'Logistic Company',
    dataIndex: 'logisticCompany',
    key: 'logisticCompany',
    sorter: false,
    width: 130,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'Latest Email Sent On',
    dataIndex: 'latestEmailSentOn',
    key: 'latestEmailSentOn',
    sorter: false,
    width: 300,
    render: (text) => <span className="font16">{[null, 'null'].includes(text) || !text ? '-' : text}</span>
  }
]

const DetailColumns = [
  {
    title: 'No.',
    dataIndex: 'seqNo',
    align: 'center',
    key: 'seqNo',
    sorter: false,
    width: 70,
    render: (text) => <div className="font16 px-text-center">{text}</div>
  },
  {
    title: 'Part',
    dataIndex: 'part',
    key: 'part',
    sorter: false,
    // width: "15%",
    width: 230,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'Serial Number',
    dataIndex: 'serialNumber',
    key: 'serialNumber',
    sorter: false,
    width: 160,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'Installation Date',
    dataIndex: 'installationDate',
    key: 'installationDate',
    sorter: false,
    width: 180,
    render: (data) => <span className="font16">{data ? dayjs(data).format('MMM-DD, YYYY HH:mm') : null}</span>
  },
  {
    title: 'Delivery Date',
    dataIndex: 'deliveryDate',
    key: 'deliveryDate',
    sorter: false,
    width: 180,
    render: (data) => <span className="font16">{data ? dayjs(data).format('MMM-DD, YYYY HH:mm') : null}</span>
  },
  {
    title: 'Package Tracking Number',
    dataIndex: 'packageTrackingNumber',
    key: 'packageTrackingNumber',
    sorter: false,
    width: 180,
    render: (text) => <span className="font16">{[null, 'null'].includes(text) ? '-' : text}</span>
  },
  {
    title: 'Logistic Company',
    dataIndex: 'logisticCompany',
    key: 'logisticCompany',
    sorter: false,
    width: 200,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'MDM Register',
    dataIndex: 'mdmRegister',
    key: 'mdmRegister',
    sorter: false,
    width: 150,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'Ethernet Mac Address',
    dataIndex: 'ethernetMacAddress',
    key: 'ethernetMacAddress',
    sorter: false,
    width: 200,
    render: (text) => <span className="font16">{text}</span>
  },
  {
    title: 'Wifi Mac Address',
    dataIndex: 'wiFiMacAddress',
    key: 'wiFiMacAddress',
    sorter: false,
    width: 200,
    render: (text) => <span className="font16">{text}</span>
  }
]

export {
  Columns,
  DetailColumns
}