/* istanbul ignore file */
import './cell-style.css'
import { useRef, useEffect, useMemo, useState } from 'react'
import { getCellProperty } from '@silevis/reactgrid'
import Select from 'react-select'

export class GridDropdownCellTemplate {


    getCompatibleCell(uncertainCell) {
        const value = getCellProperty(uncertainCell, 'value', 'string')
        const text = value.toString()

        let isOpen
        try {
            isOpen = getCellProperty(uncertainCell, 'isOpen', 'boolean')
        } catch {
            isOpen = false
        }

        // console.log('getCompatibleCell',{ ...uncertainCell, value, text ,isOpen })
        return { ...uncertainCell, value, text, isOpen }
    }

    update(cell, cellToMerge) {
        return this.getCompatibleCell({ ...cell, value: cellToMerge.value || '', isOpen: cellToMerge.isOpen })
    }

    handleCompositionEnd(cell, eventData) {
        console.log('handleCompositionEnd', cell)
        return { cell: { ...cell, inputValue: eventData, isOpen: !cell.isOpen }, enableEditMode: false }
    }

    getClassName(cell, isInEditMode) {
        const isOpen = cell.isOpen ? 'open' : 'closed'
        return `${cell.className ? cell.className : ''}${isOpen}`
    }

    render(cell, isInEditMode, onCellChanged) {

        return <div className='scdropdown' onClick={() => {
            // onCellChanged({ ...cell, isOpen: true }, true)
        }}>
            <DropdownInput onCellChanged={(cell) => {
                // console.log('onCellChanged',cell)
                onCellChanged(this.getCompatibleCell(cell), true)
            }} cell={cell} />
        </div>
    }
}


const DropdownInput = ({ onCellChanged, cell }) => {

    const selectRef = useRef(null)

    const [inputValue, setInputValue] = useState(cell.inputValue)
    const selectedValue = useMemo(() => cell.values.find(val => val.value === cell.text), [cell.text, cell.values])

    useEffect(() => {
        if (cell.isOpen && selectRef.current) {
            selectRef.current.focus()
            setInputValue(cell.inputValue)
        }
    }, [cell.isOpen, cell.inputValue])

    return <div
        style={{ width: '100%' }}
        onPointerDown={e => onCellChanged({ ...cell, isOpen: true })}
    >
        <Select
            {...(cell.inputValue && {
                inputValue,
                defaultInputValue: inputValue,
                onInputChange: e => setInputValue(e),
            })}
            isSearchable={true}
            ref={selectRef}
            {...(cell.isOpen !== undefined && { menuIsOpen: cell.isOpen })}
            onMenuClose={() => onCellChanged({ ...cell, isOpen: !cell.isOpen })}
            onMenuOpen={() => onCellChanged({ ...cell, isOpen: true })}
            onChange={(e) => {
                setTimeout(() => {
                    onCellChanged({ ...cell, value: (e).value, isOpen: false })
                }, 100)
            }}
            blurInputOnSelect={true}
            defaultValue={selectedValue}
            value={selectedValue}
            isDisabled={cell.isDisabled}
            options={cell.values}
            onKeyDown={e => {
                e.stopPropagation()

                if (e.key === 'Escape') {
                    selectRef.current.blur()
                    return onCellChanged({ ...cell, isOpen: false })
                }
            }}
            components={{
                Option: CustomOption,
                Menu: CustomMenu,
            }}
            placeholder={''}
            styles={{
                container: (provided) => ({
                    ...provided,
                    width: '100%',
                    height: '100%',
                }),
                control: (provided) => ({
                    ...provided,
                    border: 'none',
                    borderColor: 'transparent',
                    minHeight: '40px',
                    background: 'transparent',
                    boxShadow: 'none',
                }),
                indicatorsContainer: (provided) => ({
                    ...provided,
                    paddingTop: '0px',
                }),
                dropdownIndicator: (provided) => ({
                    ...provided,
                    padding: '0px 4px',
                }),
                singleValue: (provided) => ({
                    ...provided,
                    color: 'inherit'
                }),
                indicatorSeparator: (provided) => ({
                    ...provided,
                    marginTop: '4px',
                    marginBottom: '4px',
                    backgroundColor: 'hsl(0, 0%, 92%)'
                }),
                input: (provided) => ({
                    ...provided,
                    padding: 0,
                }),
                valueContainer: (provided) => ({
                    ...provided,
                    padding: '0 8px',
                }),
            }}
        />
    </div >
}

const CustomOption = ({ innerProps, label, isSelected, isFocused }) => (
    <div
        {...innerProps}
        onPointerDown={e => e.stopPropagation()}
        className={`rg-dropdown-option${isSelected ? ' selected' : ''}${isFocused ? ' focused' : ''}`}
        title={label}
    >
        {label}
    </div>
)

const CustomMenu = ({ innerProps, children }) => (
    <div {...innerProps} className='rg-dropdown-menu'>{children}</div>
)