import React from 'react';
import { CellTemplate, Cell, Compatible, Uncertain, UncertainCompatible } from '@silevis/reactgrid';
import { Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export class HTMLHeaderCellTemplate extends React.Component {
  getCompatibleCell(uncertainCell) {
    const text = uncertainCell.text;
    return { ...uncertainCell, text };
  }

  update(cell, cellToMerge) {
    return { ...cell, text: cellToMerge.text };
  }

  render(cell, isInEditMode, onCellChanged) {
    // Wrap the content in a Tooltip and adjust the visual to avoid interaction indicators
    return (
      <div className="rg-header-cell">
        <span style={{ marginRight: "5px" }}>
          {cell.text}
        </span>
        {cell.tip && <Tooltip
          title={cell.tipText || ""}
          placement="top"
          overlayInnerStyle={{
            color: '#000',
            padding: '1rem',
          }}
          color="#ffffff"
        >
          <ExclamationCircleOutlined />
        </Tooltip>}
      </div>
    );
  }
}
