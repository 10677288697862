/* istanbul ignore file */
import { post } from "./RequestApi"

export function postPurchaseOrderDetail(headers, data, id, packageId) {
  let url = `/customer/delivery/tds/purchase-order/${id}`
  if (packageId)
    url += `/${packageId}`
  return post({ url, data, headers })
}

export function postSendAssignedToShyft(headers, data) {
  const url = `/unit-installation/send-email`
  return post({ url, data, headers })
}