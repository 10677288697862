/* istanbul ignore file */
import { post, put, get } from './RequestApi'

export function getList(params, id, headers) {
  return get({ url: `/warehouse-partner/${id}`, params, headers })
}

export function getListRma(params, id, headers) {
  return get({ url: `/warehouse-partner/new-unit/${id}`, params, headers })
}
