/* istanbul ignore file */
import { post, put, get } from './RequestApi'

export function getList(params, headers) {
  return get({ url: `/unit-installation`, params, headers })
}

export function getDetail(data, id, headers) {
  return get({ url: `/unit-installation/${id}`, data, headers })
}

export function getDetailTree(data, id, headers) {
  return get({ url: `/unit-installation/checklist/${id}`, data, headers })
}

export function postUpdateStatus(data, headers) {
  return post({ url: `/unit-installation/status`, data, headers })
}

export function postUpdateChecklist(data, headers) {
  return post({ url: `/unit-installation/checklist`, data, headers })
}

export function putUpdateComment(data, id, headers) {
  return put({ url: `/unit-installation/comment/${id}`, data, headers })
}

export function postUpdateChecklistComment(data, headers) {
  return post({ url: `/unit-installation/checklist/comment`, data, headers })
}

export function postUpdateStatusCustomerDelivery(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/status`, headers, data })
}

export function postUpdateCustomerDelivery(headers, data, id) {
  return post({ url: `/tds/purchase-order/customer-delivery/${id}`, headers, data })
}

export function postGenerateAcceptance(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/acceptance/generate`, headers, data })
}

export function postPreviewAcceptance(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/acceptance/preview`, headers, data })
}

export function getCustomerDelivery(params, id, headers) {
  return get({ url: `/tds/purchase-order/customer-delivery/${id}`, params, headers })
}

export function postSendAcceptance(headers, data) {
  return post({ url: `/tds/purchase-order/customer-delivery/acceptance/send`, headers, data })
}

export function postCreateReplacementUnit(headers, data) {
  return post({ url: `/replacement/create`, headers, data })
}

export function postGenerateRMA(headers, data) {
  return post({ url: `/warehouse-partner/rma/generate`, headers, data })
}

export function getUnitReplacement(headers, id) {
  return get({ url: `/replacement/${id}`, headers})
}

export function postSerialNumberSyncMDM(data, headers) {
  return post({ url: `/stock/serial-number/sync-mdm`, data, headers })
}