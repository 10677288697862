import './App.css'
import TdsSaasUnitDelivery from './Pages/tds-saas-unit-delivery'
import UnitReplacement from './Pages/unit-replacement'
import NewUnitPage from './Pages/new-unit'
import InstallPage from './Pages/installation/installation'
import InstallDetailPage from './Pages/installation/installation-detail'
import InvestigationPage from './Pages/investigation/investigation'
import InvestigationDetailPage from './Pages/investigation/investigation-detail'
import WarehousePage from './Pages/warehouse/warehouse'
import InstallUnitPage from './Pages/installation-unit/installation-unit'
import InstallUnitDetailPage from './Pages/installation-unit/installation-unit-detail'
import { ConfigProvider } from 'antd'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from "react-router-dom"
import Demo from './Pages/checkbox-tree'
import AssignUnitInstallationPage from './Pages/assign-unit-installation/assign-unit-installation'
import AssignUnitInstallationDetailPage from './Pages/assign-unit-installation/assign-unit-installation-detail'
import AssignUnitReplacementPage from './Pages/assign-unit-replacement/assign-unit-replacement'
import AssignUnitReplacementDetailPage from './Pages/assign-unit-replacement/assign-unit-replacement-detail'

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/tds-saas-unit-delivery/:uuid" element={<TdsSaasUnitDelivery />} />
      <Route path="/installation/:token" element={<InstallPage />} />
      <Route path="/installation-detail/:token/:unitId" element={<InstallDetailPage />} />
      <Route path="/investigate/:token" element={<InvestigationPage />} />
      <Route path="/investigate-detail/:token/:unitId" element={<InvestigationDetailPage />} />
      <Route path="/package/:token" element={<InvestigationPage />} />
      <Route path="/package-detail/:token/:unitId" element={<InvestigationDetailPage />} />
      <Route path="/warehouse-partner/:token" element={<WarehousePage />} />
      <Route path="/installation-unit/:token" element={<InstallUnitPage />} />
      <Route path="/installation-unit-detail/:token/:unitId" element={<InstallUnitDetailPage />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/assign-unit-installation/:uuid/:poId" element={<AssignUnitInstallationPage />} />
      <Route path="/assign-unit-installation/:uuid/:poId/:packageId" element={<AssignUnitInstallationDetailPage />} />
      <Route path="/unit-replacement/:uuid" element={<UnitReplacement />} />
      <Route path="/new-unit/:uuid" element={<NewUnitPage />} />
      <Route path="/assign-unit-replacement/:uuid/:poId" element={<AssignUnitReplacementPage />} />
      <Route path="/assign-unit-replacement/:uuid/:poId/:packageId" element={<AssignUnitReplacementDetailPage />} />
      <Route path="/" element={<div />} />
    </Route>
  )
)


const App = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Badge: {
            statusSize: 12,
          },
          Button: {
            colorPrimary: '#313131',
          },
          Steps: {
            colorPrimary: '#F28626',
            lineWidth: 2,
            fontSizeSM: 12,
            fontWeightStrong: 700,
            colorFillContent: '#C8C8CD',
            margin: 1,
            paddingSM: 12
          },
          Form: {
            labelFontSize: 12,
            labelHeight: 20,
            labelColonMarginInlineStart: 2,
            labelColonMarginInlineEnd: 2,
            itemMarginBottom: 20,
            labelRequiredMarkColor: '#D1333D',

          },
          Input: {
            paddingBlock: 10,
            paddingInline: 12
          },
          InputNumber: {
            paddingBlock: 10,
            paddingInline: 12
          },
          Select: {
            paddingBlock: 10,
            paddingInline: 12
          },
          Checkbox: {
            borderRadiusSM: 2,
            paddingXS: 8
          },
          Switch: {
            colorPrimary: '#F28626',
            colorPrimaryHover: '#F28626',
          }
        },
        token: {
          colorBorderBg: '#F28626',
          colorBorderSecondary: '#C8C8CD',
          paddingXS: 2,
          fontSizeIcon: 16,
          colorPrimary: '#313131',
          colorText: '#17171A',
          borderRadius: 8,
          controlOutlineWidth: 1,
          fontFamily: "Open Sans",
          controlHeight: 44,
          fontSize: 16,
          colorBgContainer: '#fff',
          colorError: '#D1333D',
          paddingSM: 24,
          colorBgContainerDisabled: '#F3F5F8',
          colorTextDisabled: '#ABB3BE'
        },
      }}
    >
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </ConfigProvider>
  )
}

export default App