/* istanbul ignore file */
import { post, put, get } from './RequestApi'

export function postVerifyPurchaseOrder(data) {
  return post({ url: `/customer/delivery/tds-history/verify`, data })
}

export function postPurchaseOrderDetail(headers, data, id) {
  return post({ url: `/customer/delivery/tds/purchase-order/${id}`, headers, data })
}

export function postUploadFile(pathFile, file) {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('pathFile', pathFile)
  return post({
    url: `/customer/delivery/tds-received`,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  })
}

export function postMacAddress(headers, data) {
  return post({ url: `/customer/delivery/tds/mac-address`, headers, data })
}
// api 27
export function putUpdatePurchaseOrderDetail(headers, data, id) {
  return put({ url: `/customer/delivery/tds/purchase-order/${id}`, headers, data })
}

export function postExport(headers, data) {
  return post({ url: `/customer/delivery/tds/purchase-order/export`, responseType: 'blob', headers, data })
}
// api 66
export function putUpdateReplacementUnitDetail(headers, data, id) {
  return put({ url: `/replacement/delivery/tds/${id}`, headers, data })
}
// api 69
export function getSerialNumber(headers) {
  return get({ url: `/stock/rma-warehouse`, headers})
}
// api 75
export function postSerialNumber(headers, data) {
  return post({ url: `/stock/serial-number`, headers, data })
}

export function putUpdateNewUnitDetail(headers, data, id) {
  return put({ url: `/warehouse-partner/new-unit/${id}`, headers, data })
}