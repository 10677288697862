/* istanbul ignore file */
import { config } from '../config/index'
import axios from '../utils/axios';
import { getStorage } from '../hooks/useLocalStorage';

export const baseUrl = config.api;
export const _headers = {
    Accept: 'application/form-data',
    'Content-Type': 'application/json',
    "authorization" : getStorage("user")?.userToken
};

export function get({url, params , headers = {}}) {
    return axios({
        url,
        method: 'get',
        baseURL: baseUrl,
        headers: {..._headers , ...headers},
        params,
    })
}

export function post({url, params, data , headers = {}, responseType}) {
  // console.log("POST",url);
    return axios({
        url,
        method: 'post',
        baseURL: baseUrl,
        headers: {..._headers , ...headers},
        params,
        data,
        responseType,
    })
}

export function put({url, params, data , headers = {}}) {
    return axios({
        url,
        method: 'put',
        baseURL: baseUrl,
        headers: {..._headers , ...headers},
        params,
        data,
    })
}
