import React, { useEffect, useState } from 'react'
import {
  Button,
  Divider,
  Form,
  Input,
  Select
} from 'antd'
import {
  postAcceptance,
  postGenerateAcceptance,
  postPreviewAcceptance
} from '../../services/Investigation'
import Notification from '../../services/Notification'
import { jwtDecode } from 'jwt-decode'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import IconDocument from '../../assets/ic-document.png'
import {phoneCode} from '../../services/phoneCode'

const App = (props) => {
  const [form] = Form.useForm()
  const [orientation, setOrientation] = useState(1)
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const Noti = new Notification()
  const { token } = useParams()
  const { investigateId, poToShyftId } = jwtDecode(props?.token ?? token)
  const initialValues = {
    ...props?.viewData,
    staffTitle: props?.viewData?.staffTitle ?? 'Installation Service Partner',
    orientation: props?.viewData?.orientation ?? 1,
    prefixContact: props?.viewData?.customerPhoneNumber ? getPrefix(props?.viewData?.customerPhoneNumber, phoneCode)[0] :  "+1",
    prefixStaff:  props?.viewData?.staffPhoneNumber ? getPrefix(props?.viewData?.staffPhoneNumber, phoneCode)[0] :  "+1",
    staffPhoneNumber : props?.viewData?.staffPhoneNumber ?  getPrefix(props?.viewData?.staffPhoneNumber, phoneCode)[1] :  "",
    customerPhoneNumber : props?.viewData?.customerPhoneNumber ?  getPrefix(props?.viewData?.customerPhoneNumber, phoneCode)[1] :  "",
    note : props?.viewData?.note ?? ""
  } ?? {
    staffTitle: 'Installation Service Partner',
    orientation: 1,
    prefixContact: props?.viewData?.customerPhoneNumber ? getPrefix(props?.viewData?.customerPhoneNumber, phoneCode)[0] :  "+1",
    prefixStaff:  props?.viewData?.staffPhoneNumber ? getPrefix(props?.viewData?.staffPhoneNumber, phoneCode)[0] :  "+1",
    staffPhoneNumber : props?.viewData?.staffPhoneNumber ?  getPrefix(props?.viewData?.staffPhoneNumber, phoneCode)[1] :  "",
    customerPhoneNumber : props?.viewData?.customerPhoneNumber ?  getPrefix(props?.viewData?.customerPhoneNumber, phoneCode)[1] :  "",
    note : props?.viewData?.note ?? ""
  }
  const { TextArea } = Input;
  

  useEffect(() => {
    if (props?.isView && !initialValues?.staffName){
      setIsEdit(true)
    }else if (props?.isView || initialValues?.staffName)
      setIsEdit(false)
    setOrientation(initialValues?.orientation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function getPrefix(numberString, arr) {
    const sortedArray = arr.sort((a, b) => b.dial.length - a.dial.length);
    for (let i = 0; i < sortedArray.length; i++) {
      const element = sortedArray[i].dial;
      if (numberString.startsWith(element)) {
        return [element,numberString.substring(element.length)];
      }
    }
  
    return [null,numberString]; 
  }

  const fnPostAcceptance = (data) => {
    return new Promise((resolve, reject) => {
      postAcceptance(
        { Authorization: `Bearer ${token}` },
        {...data, investigateId}
      )
        .then(() => {
          resolve()
        })
        .catch(error => {
          console.error(error)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
          reject()
        })
    })
  }

  const fnPostGenerateAcceptance = () => {
    return new Promise((resolve, reject) => {
      const data = {
        investigateId: investigateId
      }
      postGenerateAcceptance(
        { Authorization: `Bearer ${token}` },
        data
      )
        .then(() => {
          resolve()
        })
        .catch(error => {
          console.error(error)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
          reject()
        })
    })
  }

  const fnPostPreviewAcceptance = () => {
    return new Promise((resolve, reject) => {
      const data = {
        investigateId: investigateId
      }
      postPreviewAcceptance(
        { Authorization: `Bearer ${token}` },
        data
      )
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          console.error(error)
          Noti.error({
            message: 'Error',
            description: error?.response?.data?.resultDescription
          })
          reject()
        })
    })
  }

  const onFinish = async (values) => {
    try {
      setLoading(true)
      const data = {
        staffName: values.staffName,
        staffTitle: values.staffTitle,
        staffPhoneNumber: values.prefixStaff ? values.prefixStaff + values.staffPhoneNumber : values.staffPhoneNumber,
        staffEmail: values.staffEmail,
        customerName: values.customerName,
        customerTitle: values.customerTitle,
        customerPhoneNumber: values.prefixContact ? values.prefixContact + values.customerPhoneNumber : values.customerPhoneNumber,
        customerEmail: values.customerEmail,
        orientationDate: values.orientationDate ? dayjs(values.orientationDate).format('YYYY-MM-DDTHH:mm:ss.00000Z') : undefined,
        note : values.note || null
      }
      await fnPostAcceptance(data)
      await fnPostGenerateAcceptance()
      const file = await fnPostPreviewAcceptance()
      setLoading(false)
      let d = {
        values : values,
        file : file
      }
      if(props?.viewData?.acceptanceStatus)
      d.values.acceptanceStatus = props?.viewData?.acceptanceStatus
      props.onShowPreview(d)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const onPreview = () => {
    const values = form.getFieldsValue()
    let d = {
      values : values,
      file: { url: initialValues.acceptanceFile }
    }
    if(props?.viewData?.acceptanceStatus)
      d.values.acceptanceStatus = props?.viewData?.acceptanceStatus
    props.onShowPreview(d)
  }

  return (<>
    <Divider />
    <p>{props?.p}</p>
    <Form
      form={form}
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      layout='vertical'
      initialValues={initialValues}
      size={'default'}
      style={{
        width: '100%',
        margin: 'auto',
      }}
      onFinish={onFinish}
      disabled={loading || !isEdit}
    >
      <div style={{ fontWeight: 'bold', marginTop: '2rem' }}>
        Staff Information
      </div>
      <Divider style={{ margin: '0 0 12px 0' }} />
      <Form.Item
        label='Staff Name:'
        name='staffName'
        rules={[{ required: true, message: 'Please enter Staff Name.' }]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <Input
          placeholder='Name'
        />
      </Form.Item>
      <Form.Item
        label='Staff Job Title:'
        name='staffTitle'
        rules={[{ required: true, message: 'Please enter Staff Job Title.' }]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <Input
          placeholder='Title'
        />
      </Form.Item>
      <Form.Item
        label='Staff Email:'
        name='staffEmail'
        rules={[{ required: true, message: 'Please enter Staff Email.' },{ type: 'email', message: 'Enter a valid email address' }]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <Input
          placeholder='installationpartnerstaff@example.com'
        />
      </Form.Item>
      <div className='phone-box'>
        <Form.Item 
          label='Prefix' name={`prefixStaff`} rules={[{ required: false, message: '' }]} 
          style={{ fontWeight: 'bold', paddingBottom: '1rem' }}>
          <Select
              label='Prefix'
              showSearch
              filterOption={(input, option) => {
                return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
              }}
              style={{height:"46px"}}
              defaultValue="+1"
              options={phoneCode.map(r => ({
                value: r.dial, 
                label: `${r.dial} ${r.code}`,
                name: `${r.dial} ${r.code}`
              }))}
          >
          </Select>
        </Form.Item>
        <Form.Item
        label='Staff Phone Number:'
        name='staffPhoneNumber'
        rules={[{ required: true, message: 'Please enter Staff Phone Number.' }, { pattern: /^[0-9\-]+$/, message: 'Only numbers are allowed' }]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <Input
          placeholder='2015550123'
          style={{ width: '100%' }}
          onKeyDown={(e) => {
            const regex = /^[0-9\-]+$/;
            const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
            if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
              e.preventDefault();
            }
          }}
        />
      </Form.Item>
      </div>

      <div style={{ fontWeight: 'bold', marginTop: '2rem' }}>
        Customer Information
      </div>
      <Divider style={{ margin: '0 0 12px 0' }} />
      <Form.Item
        label='Contact Person:'
        name='customerName'
        rules={[{ required: true, message: 'Please enter Contact Person.' }]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <Input
          placeholder='Name'
        />
      </Form.Item>
      <Form.Item
        label='Contact Person Job Title:'
        name='customerTitle'
        rules={[{ required: true, message: 'Please enter Contact Person Job Title.' }]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <Input
          placeholder='Title'
        />
      </Form.Item>
      <Form.Item
        label='Contact Person Email:'
        name='customerEmail'
        rules={[{ required: true, message: 'Please enter Contact Person Email.' },{ type: 'email', message: 'Enter a valid email address' }]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <Input
          placeholder='customer.email@example.com'
        />
      </Form.Item>

      <div className='phone-box'>
        <Form.Item 
          label='Prefix' name={`prefixContact`} rules={[{ required: false, message: '' }]} 
          style={{ fontWeight: 'bold', paddingBottom: '1rem' }}>
          <Select
              label='Prefix'
              showSearch
              filterOption={(input, option) => {
                return (option?.name ?? '').toLowerCase().includes((input).toLowerCase())
              }}
              style={{height:"46px"}}
              defaultValue="+1"
              options={phoneCode.map(r => ({
                value: r.dial, 
                label: `${r.dial} ${r.code}`,
                name: `${r.dial} ${r.code}`
              }))}
          >
          </Select>
        </Form.Item>
        <Form.Item
          label='Contact Person Phone Number:'
          name='customerPhoneNumber'
          rules={[{ required: true, message: 'Please enter Contact Person Phone Number.' }, { pattern: /^[0-9\-]+$/, message: 'Only numbers are allowed' }]}
          style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
        >
          <Input
            placeholder='2015550123'
            style={{ width: '100%' }}
            onKeyDown={(e) => {
              const regex = /^[0-9\-]+$/;
              const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
              if (!regex.test(e.key) && !allowedKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
          />
      </Form.Item>
      </div>

      <Form.Item
        label='Additional Note:'
        name='note'
        rules={[]}
        style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
      >
        <TextArea rows={4} placeholder='Type your Additional note.'/>
      </Form.Item>
      
      {initialValues?.acceptanceFile &&
        <Form.Item
          label='Acceptance Document'
          name='acceptanceDocument'
          style={{ fontWeight: 'bold', paddingBottom: '1rem' }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            
          >
            <img src={IconDocument} alt='' style={{ width: 24, height: 24 }} />
            <span
              style={{
                textDecoration: 'underline',
                color: '#116BEA',
                cursor: 'pointer'
              }}
              onClick={() => {
                onPreview()
              }}
            >{initialValues.acceptanceFileName ?? 'noname.pdf'}</span>
          </div>
        </Form.Item>
      }
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '1rem',
        marginTop: '2rem'
      }}>
        <Button
          type='default'
          block
          data-testid='back'
          onClick={() => {
            props.onBack()
          }}
          loading={loading}
          disabled={loading}
        >
          Back
        </Button>
        {!props?.isSigned && !props?.isGen && <>
          {isEdit &&
            <Button
              type='primary'
              block
              data-testid='submit'
              onClick={() => {
                form.submit()
              }}
              loading={loading}
              disabled={loading}
            >
              Submit
            </Button>
          }
          {!isEdit && props?.viewData?.acceptanceStatus != 2 &&
            <Button
              type='primary'
              block
              data-testid='edit'
              onClick={() => {
                setIsEdit(true)
              }}
              loading={loading}
              disabled={loading}
            >
              Edit
            </Button>
          }
        </>}
      </div>
    </Form>
  </>)
}

export default App