/* istanbul ignore file */
import React from "react";
import {
  Skeleton,
} from "antd";
const SkeletonLoading = (props) => {
  return (<div className="main-container">
    <div className={`public-form width-70p`}>
      <div className="ui-form" style={{height:400}}>
       <Skeleton active />
      </div>
    </div>
 </div>);
};

export default SkeletonLoading;
