/* eslint-disable jsx-a11y/anchor-is-valid */
/* istanbul ignore file */
import React, { useState } from 'react'
import { Input } from 'antd'
import './index.css'
import Arrow from '../../assets/comment-arrow.svg'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Avatar from 'react-avatar'
import { PictureOutlined } from '@ant-design/icons'
import Dropzone from 'react-dropzone'

dayjs.extend(relativeTime)

function blobToFile(theBlob, fileName) {
  const b = theBlob
  b.lastModified = new Date()
  b.name = fileName
  return theBlob
}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

function fnResizeImage(files, name, ratio) {
  return new Promise((resolve, reject) => {
    console.log('fnResizeImage load')
    const ele = new Image()
    ele.addEventListener('load', () => {
      // Create new canvas
      const canvas = document.createElement('canvas')

      // Draw the image that is scaled to ratio
      const context = canvas.getContext('2d')
      let w = ele.width
      let h = ele.height

      if (ele.height > 3000) {
        w = ele.width * ratio
        h = ele.height * ratio
      } else if (ele.height > 2000) {
        w = ele.width * ratio * 2
        h = ele.height * ratio * 2
      } else if (ele.height > 1000) {
        w = ele.width * ratio * 3
        h = ele.height * ratio * 3
      }
      canvas.width = w
      canvas.height = h
      context.drawImage(ele, 0, 0, w, h)
      canvas.toBlob((blobResize) => {
        let files = blobToFile(blobResize, name)
        resolve(files)
      })
    })
    toBase64(files).then(r => {
      ele.src = r
    })
  })
}

const App = (props) => {
  const [commentText, setCommentText] = useState('')
  const submitComment = () => {
    if (props.disabled) {
      return
    }
    if(commentText == null || commentText == ""  || commentText == undefined) return
    console.log(commentText)
    console.log(props)
    if (props.small) {
      props.onSubmit(commentText, props.keyId)
    } else {
      props.onSubmit(commentText)
    }
    setCommentText('')
  }

  const submitCommentImage = (e) => {
    if (props.disabled) {
      return
    }
    if (e.length && ['image/jpeg', 'image/png'].indexOf(e[0].type) > -1) {

      fnResizeImage(e[0], e[0].name, 0.20).then(r => {
        blobToBase64(r).then(rr => {
          let commentImage = `<img src='${rr}' style='max-height:300pxwidth:auto'/>`
          if (props.small) {
            props.onSubmit(commentImage, props.keyId)
          } else {
            props.onSubmit(commentImage)
          }
        })
      })
    }
  }

  return (<div style={{ padding: 8 }} >
    <div>
      <div className='panel-body form-item'>
        <div className='mail-thread'>
          {
            props.comment.map((r, i) => {
              return <span className='ng-scope' key={'comment_' + i}>
                <div className='mail-message-main'>
                  <Avatar className='pull-left avatar' name={r.author.fullName} size='40' round='50%' />
                  <div className='mail-message'>
                    <span className='arrow left'></span>
                    <span className='star left ng-hide'>
                      <i className='fa fa-asterisk'></i>
                    </span>
                    <div className='mail-message-icons'>
                      <div className='btn-group'>
                        <a className='btn btn-link dropdown-toggle'>
                          <i className='fa fa-caret-down'></i>
                        </a>
                      </div>
                    </div>
                    <div className='mail-message-body'>
                      <div dangerouslySetInnerHTML={{ __html: r.body || '' }} />
                      <div className='mail-message-files ng-hide'>
                        <ul className='inline mail-files ng-isolate-scope'>
                        </ul>
                      </div>
                      <div className='mail-message-footer'>
                        <span>
                          <span className='ng-binding'>
                            {r.author.fullName} updated document - <a title={dayjs(r.createdOn).format('DD/MM/YYYY HH:mm')}>{dayjs().to(dayjs(r.createdOn))}</a>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            })
          }
        </div>
        <div className='mail-composer ng-scope editable' style={{ position: 'relative' }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            marginTop: '.6rem',
            marginLeft: '.5rem',
            zIndex: 1000
          }}>
            <Dropzone
              accept={{
                'image/jpeg': [],
                'image/png': []
              }}
              onDrop={e => {
                submitCommentImage(e)
              }}
              disabled={props.disabled || props.loading}
            >
              {({ getRootProps, getInputProps }) => (
                <div>
                  <div {...getRootProps()} style={{ display: 'flex', width: '100%' }}>
                    <input {...getInputProps()} />
                    <span
                      className='bt-picture'
                      style={{
                        zIndex: 1000
                      }}
                    >
                      <PictureOutlined style={{ marginLeft: 'auto', marginBottom: 'auto', marginTop: 'auto', marginRigth: 'auto' }} />
                    </span>
                  </div>
                </div>
              )}
            </Dropzone>
          </div>
          <Input
            placeholder={'Add a comment'}
            value={commentText} onChange={(e) => setCommentText(e.target.value)}
            disabled={props.disabled || props.loading}
            className={`${props.small === true ? 'small-comment' : 'middle-comment'}`}
            suffix={
              <span onClick={e => {
                if (!(props.disabled || props.loading)) {
                  submitComment()
                }
              }} className='bt-sent' >
                <img src={Arrow} alt='' height='30px' width='30px' />
              </span>
            }
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                if (!(props.disabled || props.loading)) {
                  submitComment()
                }
              }
            }}
          />
        </div>
      </div>
    </div>
  </div>)
}

export default App