/* istanbul ignore file */
import { useState, createElement } from 'react'
import logoNN from "../../assets/nn1.png"
import {
  FileTextOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { Layout, Menu, Button, Drawer, ConfigProvider } from 'antd'
import { MenuOutlined } from '@ant-design/icons'

const { Header, Content, Footer, Sider } = Layout
const _items = [
  {
    key: 'tds-saas-unit-delivery',
    icon: createElement(FileTextOutlined),
    label: `NodesNow Unit Delivery`,
    link: '/tds-saas-unit-delivery'
  },
  {
    key: 'unit-replacement',
    icon: createElement(FileTextOutlined),
    label: `NodesNow Unit Replacement`,
    link: '/unit-replacement'
  },
  {
    key: 'assign-unit-installation',
    icon: createElement(FileTextOutlined),
    label: `Assign Unit Installation`,
    link: '/assign-unit-installation'
  },
  {
    key: 'assign-unit-installation',
    icon: createElement(FileTextOutlined),
    label: `Assign Unit installation`,
    link: '/assign-unit-replacement'
  },
  {
    key: 'new-unit',
    icon: createElement(FileTextOutlined),
    label: `NodesNow Material Acquisition`,
    link: '/new-unit'
  }
]
const colorBgContainer = '#F7F7F7'
const LayoutComponent = (props) => {
  const [open, setOpen] = useState(false)
  const drawerStyles = {
    mask: {
      backdropFilter: 'blur(10px)',
    },
    content: {
      boxShadow: '-10px 0 10px #666',
    },
    header: {
      // borderBottom: `1px solid ${token.colorPrimary}`,
    },
    body: {
      // fontSize: token.fontSizeLG,
    },
    footer: {
      // borderTop: `1px solid ${token.colorBorder}`,
    },
  }
  let items = []

  const path = window.location.pathname
  const splited = path?.split('/')
  if (splited && splited.length > 0) {
    const find = _items.find(item => item.link === `/${splited[1]}`)
    if (find) {
      items.push(find)
    }
  }

  return (<div>
    <Layout >
      <Sider
        className="app-ui-right"
        width={250}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          backgroundColor: '#0f0f0f'
        }}
      >
        <div style={{ display: 'flex', width: '100%' }}>
          <img
            src={logoNN}
            className="img-logo"
            alt=""
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 50,
              marginBottom: 50
            }}
          />
        </div>
        <div style={{ color: '#fff' }}>
          <Menu
            key={'menu1'}
            theme="dark"
            mode="inline"
            defaultSelectedKeys={[props.activeKey]}
            items={items}
            style={{ backgroundColor: '#0f0f0f' }}
            selectable={false}
          />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header style={{ padding: 0, backgroundColor: '#0F0F0F', }} className="header-ui" >
          <div className="mobild-ui">
            <Button icon={<MenuOutlined />} style={{ borderRadius: 6 }} className="bamberger-ui"
              onClick={() => {
                setOpen(!open)
              }}>
            </Button>
            <ConfigProvider
              drawer={{ styles: drawerStyles }}
            >
              <Drawer
                title={
                  <img
                    src={logoNN}
                    className="img-logo"
                    alt=""
                    style={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      marginTop: 0,
                      marginBottom: 0,
                      height: 50,
                      width: 50
                    }}
                    onClick={() => {
                      setOpen(!open)
                    }}></img>
                }
                placement={'left'}
                closable={false}
                onClose={() => {
                  setOpen(false)
                }}
                open={open}
                key={'left'}
                styles={drawerStyles}
                closeIcon={<CloseOutlined />}
              >
                <Menu
                  key={'menu2'}
                  theme="dark"
                  mode="inline"
                  defaultSelectedKeys={[props.activeKey]}
                  items={items}
                  style={{ backgroundColor: '#0f0f0f' }}
                />
              </Drawer>
            </ConfigProvider>
          </div>
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          <div style={{ textAlign: 'center', background: colorBgContainer }}>
            {props.children}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center', backgroundColor: 'transparent', }}>

        </Footer>
      </Layout>
    </Layout>
  </div>)
}

export default LayoutComponent