/* istanbul ignore file */
import PropTypes from 'prop-types'
import React from 'react'
import {
    Checkbox
} from 'antd'

class NativeCheckbox extends React.PureComponent {
    static propTypes = {
        indeterminate: PropTypes.bool,
    }

    static defaultProps = {
        indeterminate: false,
    }

    componentDidMount() {
        this.updateDeterminateProperty()
    }

    componentDidUpdate() {
        this.updateDeterminateProperty()
    }

    updateDeterminateProperty() {
    }

    render() {
        const props = { ...this.props }
        const testId = props['testId']
        delete props['testId']
        return <span style={{ marginLeft: 4, marginRight: 8 }} >
            <Checkbox {...props} />
            <div data-testid={'ch-' + testId} onClick={() => {
                // console.log('xxxx')
            }} style={{ display: 'none' }}> </div>
        </span>
    }

    // render() {
    //     const props = { ...this.props }
    //     console.log('props',props)
    //     // Remove property that does not exist in HTML
    //     delete props.indeterminate
    //
    //     return <input {...props} ref={(c) => { this.checkbox = c }} type='checkbox' />
    // }
}

export default NativeCheckbox
