/* istanbul ignore file */
import React, { useEffect, useState } from 'react'
import { Skeleton, Select, Input } from 'antd'
import CheckboxTree from '../component/react-checkbox-tree'
import _ from 'lodash'
import Comment from '../component/Comment'
import iconComment from '../assets/ic-comment.svg'
import Notification from '../services/Notification'

const App = (props) => {
  const [nodes, setNodes] = useState([])
  const [checked, setChecked] = useState([])
  const [checkedClone, setCheckedClone] = useState([])
  const [expanded, setExpanded] = useState([])
  const [dataList, setDataList] = useState([])
  const [dropdownValue, setDropdownValue] = useState({
    issueType: '',
    summaryIssue: '',
    nextAction: '',
    identify: '',
    description: ''
  })
  const Noti = new Notification()

  useEffect(() => {
    let node = recurse(props.data?.resData)
    if (node) {
      setNodes(node)
      setChecked(props?.data?.checked)
      setCheckedClone(props?.data?.checked)
      if (expanded.length === 0) setExpanded(props?.data?.defaultExpanded)
      setDataList(props.data.resDataList)
      if(props?.dropdownValue)
      setDropdownValue(props.dropdownValue)
    }
    console.log(props)
  }, [props.data])

  const ComList = (prop) => {
    let checklistId = prop.data.id
    // console.log('props props',props)
    return <>
      <div className=''>
        <div className='header-comment'>
          <img src={iconComment} alt=''></img>
          <span>Comment</span>
        </div>
        <Comment
          comment={prop.data.comment || []}
          onSubmit={prop.prop.postSmallComment}
          small={true}
          keyId={checklistId}
          loading={prop.loadingSmallComment}
          disabled={props.disabled || props.loading}
        />
      </div>
    </>
  }

  function recurse(node) {
    if (node && node.length) {
      for (let i = 0, count = node.length; i < count; i++) {
        if(node[i].isMandatory)
          node[i].required = true
        if (node[i]?.countComment > 0)
          node[i].postfixIcon = <img src={iconComment} alt=''></img>
        if (node[i].comment?.length >= 0)
          node[i].detail = <ComList data={node[i]} prop={props} loadingSmallComment={props.loadingSmallComment} />
        if (node[i].children) recurse(node[i].children)
      }
      return node
    }
  }

  const fnUpdateChecklist = (data, check) => {
    props.updateChecklist(data, check)
  }

  const onCheck = (value, data) => {
    console.log("🚀 ~ onCheck ~ data:", data)
    let manValue = []
    if (data && data.children) {
      if(data.isMandatory){
        if(data.countComment == 0){
          Noti.warning({
            message: '',
            description: data.reason
          })
          manValue.push(data.id)
          if(data.parent?.id)
            manValue.push(data.parent?.id)
        }
      }else{
        for (let i = 0; i < data.children.length; i++) {
          if (data.children[i].isMandatory){
            if(data.children[i].countComment == 0){
              console.log(data.children[i])
              Noti.warning({
                message: '',
                description: data.children[i].reason
              })
              manValue.push(data.children[i].id)
              if(data.children[i].parent?.id)
                manValue.push(data.children[i].parent?.id)
            }
          }
        }
      }
    }
    let d
    let ch
    if (value.length < checkedClone.length) {// ติ๊กออก
      d = _.differenceWith(checkedClone, value, _.isEqual)
      ch = false
    } else {// ติ๊กเพิ่ม
      d = _.differenceWith(value, checkedClone, _.isEqual)
      ch = true
    }

    // console.log('dataHeader',props.dataHeader)
    let dataHeader = props.dataHeader
    for (let i = 0; i < dataHeader.length; i++) {
      let checked = true
      for (let j = 0; j < dataHeader[i].children.length; j++) {
        if (value.indexOf(dataHeader[i].children[j]) === -1) {
          checked = false
        }
      }
      if (ch) {
        if (checked) {
          d.push(dataHeader[i].key)
        }
      } else {
        if (!checked) {
          d.push(dataHeader[i].key)
        }
      }
    }
    let f = d.map((x) => {
      let found = dataList.find((y) => {
        return y.code === x
      })
      return found?.id
    })
    
    f = f.filter(item => !manValue.includes(item));
    console.log("🚀 ~ onCheck ~ manValue:", manValue)
    console.log("🚀 ~ onCheck ~ value:", value)
    setChecked(value)
    setCheckedClone(value)
    fnUpdateChecklist(f, ch)
  }

  const onExpand = (value) => {
    setExpanded(value)
  }

  const handleSelectChange = (key, value) => {
    setDropdownValue(prevState => ({
      ...prevState,
      [key]: value,
      ...(key === 'nextAction' && { identify: '', description: '' })
    }));
    props.onDropdownChange({
      ...dropdownValue,
      [key]: value,
      ...(key === 'nextAction' && { identify: '', description: '' })
    })
  };

  const handleInputChange = (key, event) => {
    setDropdownValue(prevState => ({
      ...prevState,
      [key]: event.target.value
    }));
    props.onDropdownChange({
      ...dropdownValue,
      [key]: event.target.value
    })
  };

  const getLabelByValue = (options, value) => {
    const option = options?.find((opt) => opt.id === value);
    return option ? option?.name?.toLowerCase() : '';
  };

  return (<>
    {
      props.loading
        ? <>
          <div style={{ padding: 8 }}>  <Skeleton active /> </div>
        </>
        : <>
          <CheckboxTree
            checked={checked}
            expanded={expanded}
            nodes={nodes}
            onCheck={onCheck}
            onExpand={onExpand}
            disabled={props.disabled || props.loading}
            showNodeTitle={props.showNodeTitle}
          />
          {props?.pageType && props?.pageType == "investigate" && (
          <>
            <div className="invest-issue">
              <div className="invest-label">
                <label><span>* </span>Issue Type:</label>
              </div>
              <Select
                defaultValue=""
                size="large"
                className='custom-select'
                options={props?.dropdownList?.issueType?.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                value={dropdownValue.issueType}
                onChange={(value) => handleSelectChange('issueType', value)}
                disabled={props.disabled || props.loading}
              />
            </div>
            <div className="invest-issue">
              <div className="invest-label">
                <label><span>* </span>Summary of issue:</label>
              </div>
              <Input 
                style={{ padding: "10px 23px" }} 
                value={dropdownValue.summaryIssue}
                placeholder='Summary of issue'
                onChange={(event) => handleInputChange('summaryIssue', event)}
                disabled={props.disabled || props.loading}
              />
            </div>
            <div className="invest-issue">
              <div className="invest-label">
                <label><span>* </span>Next Action:</label>
              </div>
              <Select
                defaultValue=""
                size="large"
                className='custom-select'
                options={props?.dropdownList?.nextAction?.map((x) => ({
                  value: x.id,
                  label: x.name,
                }))}
                value={dropdownValue.nextAction}
                onChange={(value) => handleSelectChange('nextAction', value)}
                disabled={props.disabled || props.loading}
              />
            </div>
            {dropdownValue && getLabelByValue(props?.dropdownList?.nextAction, dropdownValue.nextAction) === "others" && <div className="invest-issue">
              <div className="invest-label">
                <label><span>* </span>Please identify:</label>
              </div>
              <Input 
                style={{ padding: "10px 23px" }} 
                value={dropdownValue.identify}
                placeholder='Please identify'
                onChange={(event) => handleInputChange('identify', event)}
                disabled={props.disabled || props.loading}
              />
            </div>}
            {dropdownValue && getLabelByValue(props?.dropdownList?.nextAction, dropdownValue.nextAction) === "unit replacement" && <div className="invest-issue">
              <div className="invest-label">
                <label><span>* </span>Description:</label>
              </div>
              <Input 
                style={{ padding: "10px 23px" }} 
                value={dropdownValue.description}
                placeholder='Description'
                onChange={(event) => handleInputChange('description', event)}
                disabled={props.disabled || props.loading}
              />
            </div>}
          </>
          )}
          <div className='main-comment'>
            <div className='header-comment'>
              <img src={iconComment} alt=''></img>
              <span>Comment</span>
            </div>
            <Comment
              comment={props.data.comment || []}
              onSubmit={props.postComment}
              disabled={props.disabled || props.loading}
            />
          </div>
        </>
    }
  </>)
}

export default App